import Router from "./Router";
import { Menu } from "./components";
import { useState, useEffect } from "react";
import { ContrastContext } from "./context";

function App() {
  const [contrast, setContrast] = useState(false);
  useEffect(() => {
    document.documentElement.style.setProperty('--page-background-color', contrast ? '#FFF' : '#FFBA49');
    document.documentElement.style.setProperty('--font-color-primary', contrast ? '#000' : '#FFF');
  }, [contrast])
  return (
    <div className="App">
      <ContrastContext.Provider value={{contrast, setContrast}}>
        <Menu />
        <Router />
      </ContrastContext.Provider>
    </div>
  );
}

export default App;
