import { Routes, Route } from "react-router-dom";

import { Home, Photography, NotFound } from "./components";

const Router = () => (
    <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/jolielaide" element={<Photography />}></Route>
        <Route path="/*" element={<NotFound />}></Route>
    </Routes>
);
export default Router;
