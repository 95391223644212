import { Highlight } from 'components';
import {useState, useEffect} from 'react';
const moment = require('moment');

function Home() {
    const [yearsOfExp, setYearsOfExp] = useState(5);
    const calculateYearsOfExp = () => {
        const years = moment().diff('2017-05-01', 'years', false);
        setYearsOfExp(years);
    }
    useEffect(() => {
        calculateYearsOfExp();
    })

    return (
        <div className="Home">
            <h1>Celine</h1>
            <h1>Longeville</h1>
            <div>
                <p>Welcome to my playground! I'm a passionate <Highlight>frontend developer</Highlight> with {yearsOfExp}+ years of experience.</p>
                <p>Visit me on <a href="https://www.linkedin.com/in/celinelongeville/" target="_blank" rel="noopener noreferrer">LinkedIn</a>.</p>
            </div>
        </div>
    );
}

export default Home;
