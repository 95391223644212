function Photography() {
    return (
        <div className="Photography">
            <h1>Jolie Laide Photography</h1>
            <div>
                <p>Coming soon!</p>
                <p>Find me on <a href="https://www.instagram.com/jolie.laide.photography/" target="_blank" rel="noopener noreferrer">instagram</a> in the mean time.</p>
            </div>
        </div>
    );
}

export default Photography;
