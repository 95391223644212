import { useContrast } from "context";
import { NavLink } from "react-router-dom";
import { ContrastIcon } from "../../components";

function Menu() {
    const {contrast, setContrast} = useContrast();
    return (
        <div className="Menu">
            <ul>
                <li className="menu-item">
                    <NavLink to="/">code</NavLink>
                </li>
                <li className="menu-item">
                    <NavLink to="/jolielaide">photography</NavLink>
                </li>
                <li className="menu-item contrast-icon" onClick={() => setContrast(!contrast)}>
                    <ContrastIcon />
                </li>
            </ul>
        </div>
    );
}

export default Menu;
