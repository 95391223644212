import { NavLink } from "react-router-dom";

function NotFound() {
    return (
        <div className="NotFound">
            <h1>Oops! 404</h1>
            <p>Looks like this page doesn't exist... Would you like to return <NavLink to="/">home</NavLink>?</p>
        </div>
    );
}

export default NotFound;
